<template>
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label">Language</label>
    </div>
    <div class="field-body">
      <div class="field">
        <div class="control is-expanded" style="width: 100%;">
          <div class="dropdown" style="width: 100%;" :class="{ 'is-active': open}">
            <div class="dropdown-trigger" style="    width: 100%;">
              <div class="field">
                <div class="control has-icons-left has-icons-right">
                  <input class="input is-primary" :class="{ 'hovered': over}" :value="currentlang" disabled />
                  <div
                    class="fakeclicker"
                    @mouseover="over = true"
                    @mouseout="over = false"
                    @click.prevent="open = !open"
                    style="position:absolute; left:0; right:0; top:0; bottom:0;"
                  ></div>
                  <span class="icon is-small is-left">
                    <font-awesome-icon style="color: #5a5e5b" icon="language"></font-awesome-icon>
                  </span>
                  <span class="icon is-small is-right">
                    <font-awesome-icon style="color: #5a5e5b" icon="chevron-down"></font-awesome-icon>
                  </span>
                </div>
              </div>
            </div>
            <div class="dropdown-menu" role="menu" style="width: 100%">
              <div class="dropdown-content">
                <a
                  v-for="(lang, index) in all"
                  :key="index"
                  class="dropdown-item"
                  @click="handleClicks(lang)"
                >{{ lang }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const ISO6391 = require("iso-639-1");

export default {
  name: "languagedropdown",
  data() {
    return {
      currentlang: "English",
      currentlangcode: "en",
      open: false,
      over: false,
      all: ISO6391.default.getAllNames().sort()
    };
  },
  methods: {
    handleClicks(lang) {
      this.open = false;
      this.currentlang = lang;
      this.currentlangcode = ISO6391.default.getCode(lang);
    }
  },
  watch: {
    currentlangcode() {
      this.$emit("langChange", this.currentlangcode);
    }
  }
};
</script>

<style scoped>
.input[disabled] {
  cursor: pointer;
  /* border: 1.5px solid grey; */
  background: transparent;
}

.hovered {
  cursor: pointer;
  border: 1.5px solid grey;
  opacity: 0.7;
}

.fakeclicker:hover .input[disabled] {
  opacity: 0.7;
}
</style>