<template>
  <div class="column is-2-desktop is-6-mobile" style="margin-bottom: 1rem;">
    <figure class="image is-2by3">
      <router-link :to="{ name: type, params: {id: info.id } }">
        <img class="border-top" :src="image" alt="Poster image" style="border-radius: 3px" />
      </router-link>
    </figure>
  </div>
</template>

<script>
import TMDb from "@/utils/TMDb.js";

const tmdb = new TMDb();

export default {
  name: "simplecard",
  props: ["info", "type"],
  data() {
    return {
      notfound: require("@/assets/images/unknown.png")
    };
  },
  computed: {
    image() {
      return this.info.poster_path != null
        ? "https://image.tmdb.org/t/p/w500/" + this.info.poster_path
        : this.notfound;
    },
    title() {
      return this.type == "tv" ? this.info.name : this.info.title;
    }
  }
};
</script>
