<template>
  <div>
    <div class="container is-fluid">
      <div style="height: max-content">
        <!-- MOBILE ------------------------- -->
        <div @click="toggle($event)" class="buttons-group is-hidden-desktop">
          <scrollmenu style="margin-bottom: 0">
            <div class="btn-grp">
              <p style="font-weight: 700 !important">Age rating</p>
              <a
                class="button is-rounded"
                v-for="(rating, index) in ratings"
                :key="index"
                :title="rating.meaning + `\n\nSelect two or more ratings to form a range, then all ratings between them will also be counted. Eg. If you select 'G' and 'R', then moves with ratings of 'PG' and 'PG-13' will also be shown, since they are between 'G' and 'R'`"
                :data-id="rating.certification + ',' + rating.order"
                data-for="Rating"
              >{{ rating.certification }}</a>
            </div>
          </scrollmenu>
          <scrollmenu>
            <div class="btn-grp">
              <p style="font-weight: 700 !important">Genres</p>
              <a
                class="button is-rounded"
                :title="'Click to toggle between states\n\n- Blue outlined buttons are unselected genres.\n- Solid blue buttons are genres to include in the search.\n- Solid red buttons are genres NOT to include in the search.\n\nJust in case you were wondering, the TMDb genre id for this genre is ' + (genre.title != undefined ? genre.title : genre.id) + '.'"
                v-for="(genre, index) in genres"
                :key="index"
                :data-id="genre.id"
                data-for="Genres"
              >{{ genre.name }}</a>
            </div>
          </scrollmenu>

          <div style="margin-bottom: 4rem">
            <slider @yearChange="editYear($event, 'mobile slider')" :mobile="true"></slider>
          </div>
          <div style="margin-bottom: 5rem">
            <voteslider @voteChange="editVote($event)" :mobile="true"></voteslider>
          </div>
          <div v-if="expanded">
            <div class="columns" style="margin-bottom: 2rem">
              <div
                class="column is-6"
                style="margin-left: -0.75rem; margin-right: -0.75rem; margin-bottom: 2rem"
              >
                <votecount @voteCountChange="editVoteCount($event)"></votecount>
              </div>
              <div class="column is-6" style="margin-left: -0.75rem; margin-right: -0.75rem">
                <languagedropdown @langChange="editLang($event)"></languagedropdown>
              </div>
            </div>
            <div style="margin-bottom: 2rem; margin-left: -0.75rem; margin-right: -0.75rem;">
              <runtime @runtimeChange="editRuntime($event)" :mobile="false"></runtime>
            </div>

            <div style="margin-bottom: 4rem; margin-left: -0.75rem; margin-right: -0.75rem">
              <sortby @sortChange="editSorting($event)"></sortby>
            </div>
          </div>
          <div style="text-align: center; width: 100%">
            <button
              style="margin-bottom: 3rem;"
              class="button is-info is-rounded"
              @click="expanded = !expanded; expandmsg == 'More' ? expandmsg = 'Less' : expandmsg = 'More'"
            >
              <p style="vertical-align: middle; margin-right: 7px">{{ expandmsg }}</p>
              <font-awesome-icon
                style="vertical-align: middle"
                v-if="!expanded"
                icon="chevron-down"
              ></font-awesome-icon>
              <font-awesome-icon style="vertical-align: middle" v-else icon="chevron-up"></font-awesome-icon>
            </button>
          </div>
        </div>

        <!-- DESKTOP -------------------- -->
        <div
          @click="toggle($event)"
          class="columns is-multiline is-centered buttons-group is-hidden-mobile is-hidden-tablet-only"
        >
          <!-- LEFT ------------- -->
          <div class="column is-5">
            <div class="btn-grp">
              <p style="font-weight: 700 !important">Age rating</p>
              <a
                class="button is-rounded"
                v-for="(rating, index) in ratings"
                :key="index"
                :title="rating.meaning + `\n\nSelect two or more ratings to form a range, then all ratings between them will also be counted. Eg. If you select 'G' and 'R', then moves with ratings of 'PG' and 'PG-13' will also be shown, since they are between 'G' and 'R'`"
                :data-id="rating.certification + ',' + rating.order"
                data-for="Rating"
              >{{ rating.certification }}</a>
            </div>
            <div style="margin-right:5rem; margin-bottom: 5rem;">
              <voteslider @voteChange="editVote($event)"></voteslider>
            </div>
            <div v-if="expanded">
              <div style="margin-right:5rem; margin-bottom:4rem">
                <div class="columns">
                  <div class="column is-6">
                    <votecount @voteCountChange="editVoteCount($event)"></votecount>
                  </div>
                  <div class="column is-6">
                    <languagedropdown @langChange="editLang($event)"></languagedropdown>
                  </div>
                </div>
              </div>

              <div style="margin-right: 5rem; margin-bottom: 4rem">
                <sortby @sortChange="editSorting($event)"></sortby>
              </div>
            </div>
          </div>

          <!-- RIGHT --------------- -->
          <div class="column is-7">
            <div class="btn-grp">
              <p style="font-weight: 700 !important">Genres</p>
              <a
                class="button is-rounded"
                :title="'Click to toggle between states\n\n- Blue outlined buttons are unselected genres.\n- Solid blue buttons are genres to include in the search.\n- Solid red buttons are genres NOT to include in the search.\n\nJust in case you were wondering, the TMDb genre id for this genre is ' + (genre.title != undefined ? genre.title : genre.id) + '.'"
                v-for="(genre, index) in genres"
                :key="index"
                :data-id="genre.id"
                data-for="Genres"
              >{{ genre.name }}</a>
            </div>
            <div style="margin-bottom: 5rem;">
              <slider @yearChange="editYear($event, 'desktop slider')" :mobile="false"></slider>
            </div>
            <div v-if="expanded">
              <div style="margin-bottom: 2rem; margin-left: -0.75rem; margin-right: -0.75rem;">
                <runtime @runtimeChange="editRuntime($event)" :mobile="false"></runtime>
              </div>
            </div>
          </div>
          <button
            style="margin-bottom: 3rem"
            class="button is-info is-rounded"
            @click="expanded = !expanded; expandmsg == 'More' ? expandmsg = 'Less' : expandmsg = 'More'"
          >
            <p style="vertical-align: middle; margin-right: 7px">{{ expandmsg }}</p>
            <font-awesome-icon style="vertical-align: middle" v-if="!expanded" icon="chevron-down"></font-awesome-icon>
            <font-awesome-icon style="vertical-align: middle" v-else icon="chevron-up"></font-awesome-icon>
          </button>
        </div>

        <!-- tv cards -->
        <div class="columns is-mobile is-multiline">
          <card v-for="(tvshow, index) in tvshows" :info="tvshow" :key="index" type="tv"></card>
        </div>

        <infinite-loading @infinite="infiniteTvHandler" v-if="!tvfirstrun">
          <div slot="no-results">
            <p class="subtitle is-5" style="padding-top:3rem; padding-bottom:5rem">No more items</p>
          </div>
          <div slot="no-more">
            <p class="subtitle is-5" style="padding-top:3rem; padding-bottom:5rem">No more items</p>
          </div>
        </infinite-loading>
      </div>
    </div>
  </div>
</template>

<script>
import TMDb from "@/utils/TMDb.js";
import InfiniteLoading from "vue-infinite-loading";
import Card from "@/components/Simplecard.vue";
import Scrollmenu from "@/components/Sidescroll/Scrollmenu.vue";
import Slider from "@/components/discover/Slider.vue";
import Voteslider from "@/components/discover/Voteslider.vue";
import Runtime from "@/components/discover/Runtime.vue";
import Votecount from "@/components/discover/Votecount.vue";
import Languagedropdown from "@/components/discover/Languagedropdown.vue";
import Sortby from "@/components/discover/Sortby.vue";

const tmdb = new TMDb();
Window.tmdb = tmdb;

export default {
  name: "discovertv",
  data() {
    return {
      expanded: false,
      expandmsg: "More",
      tvshows: [],
      tvpage: 1,
      tvtotalpages: 1,
      pagesloaded: 0,
      tvfirstrun: true,
      tvveryfirstrun: true,
      genres: [],
      ratings: [],
      paramcontainer: {
        ratings: [],
        withgenres: [],
        withoutgenres: []
      },
      params: {
        page: 1, //
        language: "en-US", //
        certification_country: "US", //
        "certification.lte": "", //
        "certification.gte": "", //
        with_genres: "", //
        without_genres: "", //
        "with_runtime.gte": 0,
        "with_runtime.lte": 99999999 //
      },
      tvparams: {}
    };
  },
  components: {
    Card,
    InfiniteLoading,
    Scrollmenu,
    Slider,
    Voteslider,
    Votecount,
    Runtime,
    Languagedropdown,
    Sortby
  },
  watch: {
    paramcontainer: {
      deep: true,
      handler: _.debounce(function() {
        console.log("paramcontainer was modified");
        this.paramsFormatter("the watcher"); //paramsFormatter is a function; it is called by the deep watcher when changes are made to the paramcontainer obj. For some reason, the watcher is not called when a genre is unselected when 'Only' is not selected, so paramsFormatter has to be called in the remove() Function also.
      }, 250)
    }
  },
  mounted() {
    this.setup();
  },
  methods: {
    infiniteTvHandler($state) {
      if (this.tvpage >= this.tvtotalpages && this.tvfirstrun == false) {
        console.log("Finished loading all tv shows");
        $state.complete();
      } else if (this.pagesloaded >= 20) {
        $state.loaded();
        this.pagesloaded = 0;
      } else {
        this.fetchTvPage(this.tvpage + 1, "infiniteTvHandler").then(() => {
          console.log("Finished loading tv page " + this.tvpage);
          this.pagesloaded = this.tvpage;
          $state.loaded();
        });
      }
    },
    fetchTvPage: function(page = 1, from = "here") {
      this.params.page = page;
      return new Promise((resolve, reject) => {
        tmdb.discoverTv(this.params).then(resp => {
          console.log(resp.data.results);
          let m = this.tvshows.slice(0);
          resp.data.results.forEach(tv => {
            // this.tvshows.push(tv);
            if (this.paramcontainer.ratings.length > 0) {
              tmdb.getTvCertification(tv.id).then(cert => {
                this.paramcontainer.ratings.forEach(rating => {
                  if (cert == rating.cert) {
                    // console.log(tv.name + " - " + tv.id);
                    m.push(tv);
                    this.tvshows = _.uniqBy(m, "id");
                  }
                });
              });
            } else {
              m.push(tv);
              this.tvshows = _.uniqBy(m, "id");
            }
            this.tvpage = resp.data.page;
            this.tvtotalpages = resp.data.total_pages;
            this.tvfirstrun = false;
            console.log("Fetched tv page " + page + " from " + from);
            resolve();
          });
        });
      }).catch(error => {
        console.error(error);
        reject();
      });
    },
    resetTvs() {
      this.tvshows = [];
      this.tvpage = 1;
      this.tvtotalpages = 1;
      this.tvfirstrun = true;
    },
    editYear(range, from = "here") {
      console.log("Calling editYear from " + from);

      this.params["first_air_date.gte"] = new Date(range[0], 0).toISOString();
      this.params["first_air_date.lte"] = new Date(
        range[1],
        12,
        31
      ).toISOString(); // THIS RETURNS 01-31 INSTEAD OF 12-31.
      this.paramsFormatter("editYear()");
    },
    editLang(lang) {
      this.params["language"] = lang;
      this.paramsFormatter();
    },
    editVote(range) {
      this.params["vote_average.gte"] = range[0];
      this.params["vote_average.lte"] = range[1];
      this.paramsFormatter("editVote()");
    },
    editVoteCount(min) {
      this.params["vote_count.gte"] = min;
      this.paramsFormatter("editVoteCount()");
    },
    editRuntime(runtime) {
      runtime.type == "min"
        ? (this.params["with_runtime.gte"] = runtime.value)
        : (this.params["with_runtime.lte"] = runtime.value);
      this.paramsFormatter("editRuntime()");
    },
    editPeople(ids) {
      //unused, since people are not supported in discover tv
      this.params["with_people"] = ids;
      this.paramsFormatter();
    },
    editSorting(code) {
      this.params["sort_by"] = code;
      this.paramsFormatter();
    },
    setup() {
      this.getTvGenres();
      this.getTvCertifications();
      this.paramsFormatter("setup()");
    },
    add(group, value) {
      console.log("Selected: " + group + " - " + value);
      switch (group) {
        case "Rating":
          //value is in the format PG,2 where PG is the rating and 2 is the order, ie. NR = 0, G = 1, PG = 2, PG-13 = 3, R = 4, NC-17 = 5
          let cert = value.split(",")[0];
          let order = value.split(",")[1];

          this.paramcontainer.ratings.push({ cert: cert, order: order });
          this.ratingFormatter();
          break;
      }
    },
    remove(group, value) {
      switch (group) {
        case "Rating":
          let cert = value.split(",")[0];
          _.remove(this.paramcontainer.ratings, c => c.cert == cert);
          this.paramcontainer.ratings.length == 0
            ? (this.params["certification.gte"] = this.params[
                "certification.lte"
              ] = "")
            : {};
          if (this.paramcontainer.ratings.length > 0) this.ratingFormatter();
          this.paramsFormatter("remove()");
          break;
        case "Genres":
          let id = value;
          _.remove(this.paramcontainer.withgenres, genre => genre == id);
          _.remove(this.paramcontainer.withoutgenres, genre => genre == id);
          this.paramsFormatter("remove()");
          break;
      }
    },
    ratingFormatter() {
      let sorted = _.sortBy(this.paramcontainer.ratings, ["order"]); //Sort the ratings so that the lowest will be on the top and the highest on the bottom
      let first = sorted[0]; // Lowest rating
      let last = sorted.slice(-1).pop(); //Highest rating

      //   this.params["certification.gte"] = first.cert; //The certification must be greater or equal to the lowest one.
      //   this.params["certification.lte"] = last.cert; //The certification must be less than or equal to the highest one.
      //this.params["certification"] = first.cert;
    },
    paramsFormatter(from = "here") {
      console.log("Calling paramsFormatter from " + from);
      let p = this.paramcontainer;
      this.params["with_genres"] = p.withgenres.toString();
      this.params["without_genres"] = p.withoutgenres.toString();
      this.resetTvs();
      this.fetchTvPage(undefined, "paramsFormatter");
      this.tvveryfirstrun = false;
    },
    select(group, value) {
      switch (group) {
        case "Genres":
          let id = value;
          _.remove(this.paramcontainer.withoutgenres, genre => genre == id);
          this.paramcontainer.withgenres.push(id);
          break;
      }
    },
    deselect(group, value) {
      switch (group) {
        case "Genres":
          let id = value;
          _.remove(this.paramcontainer.withgenres, genre => genre == id);
          this.paramcontainer.withoutgenres.push(id);
          break;
      }
    },
    toggle(click) {
      if (click.target.tagName == "A") {
        let button = click.target;
        if (button.getAttribute("data-for") != "Genres") {
          if (button.classList.contains("clicked")) {
            button.classList.remove("clicked");
            this.remove(
              button.getAttribute("data-for"),
              button.getAttribute("data-id")
            );
          } else {
            button.classList.add("clicked");
            this.add(
              button.getAttribute("data-for"),
              button.getAttribute("data-id")
            );
          }
        } else {
          // alert('sup')
          //clicked = blue
          //red = red
          if (button.classList.contains("clicked")) {
            button.classList.remove("clicked");
            button.classList.add("red");
            this.deselect(
              button.getAttribute("data-for"),
              button.getAttribute("data-id")
            );
          } else if (button.classList.contains("red")) {
            button.classList.remove("red");
            this.remove(
              button.getAttribute("data-for"),
              button.getAttribute("data-id")
            );
          } else {
            button.classList.add("clicked");
            this.select(
              button.getAttribute("data-for"),
              button.getAttribute("data-id")
            );
          }
        }
        button.blur();
      }
    },
    getTvGenres() {
      tmdb.getTvGenres().then(resp => {
        this.genres = resp.data.genres;
      });
    },
    getTvCertifications() {
      tmdb
        .getTvCertifications()
        .then(
          resp =>
            (this.ratings = _.sortBy(resp.data.certifications.US, ["order"]))
        );
    }
  }
};
</script>

<style scoped>
.btn-grp {
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.btn-grp p {
  margin-right: 1rem;
  display: inline-block;
}

.column {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.btn-grp a {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  background: transparent;
  border: 1.5px solid #09c0f4;
  color: #09c0f4;
  display: inline-block;
  vertical-align: middle;
}

.clicked {
  background-color: #09c0f4 !important;
  color: white !important;
}

.red {
  background-color: red !important;
  color: white !important;
  border-color: red !important;
}
</style>

