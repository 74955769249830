<template>
  <div class="field is-horizontal">
    <div class="field-label" style="flex-grow: unset !important; ">
      <label class="label">Minimum votes</label>
    </div>
    <div class="field-body">
      <div class="field">
        <p class="control is-expanded has-icons-left">
          <span class="icon is-small is-left">
            <font-awesome-icon style="color: #5a5e5b" icon="star"></font-awesome-icon>
          </span>
          <input
            v-model="min"
            class="input is-primary"
            type="number"
            placeholder="Minimum vote count. Eg. 500"
            title="Minimum vote count. Eg. 500"
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "runtime",
  watch: {
    min: _.debounce(function() {
      this.$emit("voteCountChange", this.min);
    }, 500)
  },
  data() {
    return {
      min: ""
    };
  }
};
</script>
