<template>
  <div>
    <phlix-navbar></phlix-navbar>
    <div class="container is-fluid">
      <h1
        class="title is-size-2-desktop is-size-4-mobile"
        style="margin-bottom: 2rem; margin-left: -0.75rem"
      >Discover something to watch</h1>
      <scrollmenu style="margin-bottom: 0rem">
        <div class="btn-grp">
          <a
            @click="showmoviepage = true"
            :class="{'clicked': showmoviepage}"
            class="button is-rounded"
          >Movie</a>
          <a
            @click="showmoviepage = false"
            :class="{'clicked': !showmoviepage}"
            class="button is-rounded"
          >TV</a>
        </div>
      </scrollmenu>
    </div>
    <discovermovies :class="{'hide': !showmoviepage}"></discovermovies>
    <discovertv :class="{'hide': showmoviepage}"></discovertv>
    <bottomnavbar item="discover"></bottomnavbar>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Discovermovies from "@/components/discover/mainComponents/Discovermovies.vue";
import Discovertv from "@/components/discover/mainComponents/Discovertv.vue";
import Scrollmenu from "@/components/Sidescroll/Scrollmenu.vue";
import Bottomnavbar from "@/components/Bottomnavbar.vue";

export default {
  name: "discover",
  data() {
    return {
      showmoviepage: true
    };
  },
  components: {
    "phlix-navbar": Navbar,
    Discovermovies,
    Discovertv,
    Scrollmenu,
    Bottomnavbar
  }
};
</script>

<style scoped>
.btn-grp {
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.btn-grp p {
  margin-right: 1rem;
  display: inline-block;
}

.btn-grp a {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  background: transparent;
  border: 1.5px solid #09c0f4;
  color: #09c0f4;
  display: inline-block;
  vertical-align: middle;
}

.clicked {
  background-color: #09c0f4 !important;
  color: white !important;
}

.hide {
  display: none;
}
</style>

