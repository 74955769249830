<template>
  <div class="field is-horizontal" style="width: 100%">
    <div class="field-label is-normal">
      <label class="label">Sort by</label>
    </div>
    <div class="field-body" style="flex-grow: 11">
      <div class="field">
        <div class="control is-expanded" style="width: 100%;">
          <div class="dropdown" style="width: 100%;" :class="{ 'is-active': open}">
            <div class="dropdown-trigger" style="    width: 100%;">
              <div class="field">
                <div class="control has-icons-left has-icons-right">
                  <input
                    class="input is-primary"
                    :class="{ 'hovered': over}"
                    :value="currentsortoption"
                    disabled
                  />
                  <div
                    class="fakeclicker"
                    @mouseover="over = true"
                    @mouseout="over = false"
                    @click.prevent="open = !open"
                    style="position:absolute; left:0; right:0; top:0; bottom:0;"
                  ></div>
                  <span class="icon is-small is-left">
                    <font-awesome-icon style="color: #5a5e5b" icon="sort-amount-down-alt"></font-awesome-icon>
                  </span>
                  <span class="icon is-small is-right">
                    <font-awesome-icon style="color: #5a5e5b" icon="chevron-down"></font-awesome-icon>
                  </span>
                </div>
              </div>
            </div>
            <div class="dropdown-menu" role="menu" style="width: 100%">
              <div class="dropdown-content">
                <a
                  v-for="(sort, index) in all"
                  :key="index"
                  class="dropdown-item"
                  @click="handleClicks(sort)"
                >{{ sort.name }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sortby",
  data() {
    return {
      currentsortoption: "Popularity - Descending",
      currentsortcode: "popularity.desc",
      open: false,
      over: false,
      all: [
        {
          name: "Popularity - Ascending",
          code: "popularity.asc"
        },
        {
          name: "Popularity - Descending",
          code: "popularity.desc"
        },
        {
          name: "Release Date - Ascending",
          code: "release_date.asc"
        },
        {
          name: "Release Date - Descending",
          code: "release_date.desc"
        },
        {
          name: "Revenue - Ascending",
          code: "revenue.asc"
        },
        {
          name: "Revenue - Descending",
          code: "revenue.desc"
        },
        {
          name: "Primary Release Date - Ascending",
          code: "primary_release_date.asc"
        },
        {
          name: "Primary Release Date - Descending",
          code: "primary_release_date.desc"
        },
        {
          name: "Original Title - Ascending",
          code: "original_title.asc"
        },
        {
          name: "Original Title - Descending",
          code: "original_title.desc"
        },
        {
          name: "Vote Average - Ascending",
          code: "vote_average.asc"
        },
        {
          name: "Vote Average - Descending",
          code: "vote_average.desc"
        },
        {
          name: "Vote Count - Ascending",
          code: "vote_count.asc"
        },
        {
          name: "Vote Count - Descending",
          code: "vote_count.desc"
        }
      ]
    };
  },
  methods: {
    handleClicks(sort) {
      console.log("clicked + ");
      console.log(sort);
      this.open = false;
      this.currentsortoption = sort.name;
      this.currentsortcode = sort.code;
    }
  },
  watch: {
    currentsortcode() {
      this.$emit("sortChange", this.currentsortcode);
    }
  }
};
</script>

<style scoped>
.input[disabled] {
  cursor: pointer;
  /* border: 1.5px solid grey; */
  background: transparent;
}

.hovered {
  cursor: pointer;
  border: 1.5px solid grey;
  opacity: 0.7;
}

.fakeclicker:hover .input[disabled] {
  opacity: 0.7;
}
</style>
