<template>
  <div>
    <div class="field is-horizontal">
      <div class="field-label is-normal" style="flex-grow: unset !important; ">
        <label class="label">Runtime</label>
      </div>
      <div class="field-body">
        <div class="field">
          <p class="control is-expanded has-icons-left">
            <span class="icon is-small is-left">
              <font-awesome-icon style="color: #5a5e5b" :icon="['far', 'clock']"></font-awesome-icon>
            </span>
            <input
              v-model="min"
              class="input is-primary"
              type="number"
              placeholder="Min runtime (minutes)"
            />
          </p>
        </div>
        <div class="field">
          <p class="control is-expanded has-icons-left">
            <span class="icon is-small is-left">
              <font-awesome-icon style="color: #5a5e5b" icon="clock"></font-awesome-icon>
            </span>
            <input
              v-model="max"
              class="input is-primary"
              type="number"
              placeholder="Max runtime (minutes)"
            />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "runtime",
  watch: {
    min: _.debounce(function() {
      this.$emit("runtimeChange", { type: "min", value: this.min });
    }, 500),
    max: _.debounce(function() {
      this.$emit("runtimeChange", { type: "max", value: this.max });
    }, 500)
  },
  data() {
    return {
      min: "",
      max: ""
    };
  }
};
</script>
