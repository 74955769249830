<template>
  <scrollmenu :showArrows="true">
    <slot />
  </scrollmenu>
</template>

<script>
import Scrollmenu from "@/components/Sidescroll/Scrollmenu.vue";

export default {
  name: "imageslider",
  components: {
    Scrollmenu
  }
};
</script>

<style scoped>
</style>