<template>
  <div>
    <div class="field is-horizontal">
      <div class="field-label is-normal" style="flex-grow: unset !important; ">
        <label class="label">People</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control has-icons-left">
            <span class="icon is-small is-left">
              <font-awesome-icon style="color: #5a5e5b" icon="user-ninja"></font-awesome-icon>
            </span>
            <input
              class="input is-primary"
              type="text"
              placeholder="Search for actors, directors...etc."
              v-model="query"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="tags" style="margin-top: 1rem">
      <span
        v-if="msg != ''"
        class="tag is-primary"
        style="font-weight: 400; font-variant: small-caps"
      >{{ msg }}</span>
      <span v-for="(person, index) in selectedpeople" :key="index" class="tag is-info is-rounded">
        {{ person.name }}
        <button @click="handleDeletes(person.id)" class="delete is-small"></button>
      </span>
    </div>
    <div v-if="people.length > 0" style="margin-bottom: 4rem; margin-top: 2rem">
      <imageslider>
        <captionedimage
          v-for="(person, index) in people"
          :key="index"
          :path="person.profile_path"
          :id="person.id"
          :caption="person.name"
          @personWasClicked="handlePersonClicks($event)"
        ></captionedimage>
      </imageslider>
    </div>
    <div v-else-if="people.length == 0 && query != ''" style="margin-top: 2rem">
      <p class="subtile has-text-centered">No results found</p>
    </div>
  </div>
</template>

<script>
import Imageslider from "@/components/Imageslider.vue";
import Captionedimage from "@/components/discover/Captionedimage.vue";
import TMDb from "@/utils/TMDb.js";
import { Bus } from "@/utils/Bus.js";

const tmdb = new TMDb();

export default {
  name: "castpicker",
  components: {
    Imageslider,
    Captionedimage
  },
  data() {
    return {
      query: "",
      msg: "",
      people: [],
      selectedpeople: []
    };
  },
  watch: {
    query: _.debounce(function() {
      this.searchPeople(this.query);
    }, 250),

    selectedpeople: {
      deep: true,
      handler() {
        let ids = [];
        this.selectedpeople.forEach(person => ids.push(person.id));
        this.$emit("personWasSelected", ids.toString());
      }
    }
  },
  methods: {
    searchPeople(query) {
      tmdb.searchPeople(query, 1).then(resp => {
        Bus.$emit("scroll");
        console.log(resp);
        this.people = resp.data.results;
        if (this.selectedpeople.length == 0) {
          this.msg = "";
        }
        // resp.results.forEach(person => this.people.push(person));
      });
    },
    handlePersonClicks(person) {
      console.log(person);
      let isAlreadySelected = _.some(this.selectedpeople, ["id", person.id]);
      if (!isAlreadySelected) {
        this.selectedpeople.push(person);
        this.msg = "selected";
      }
    },
    handleDeletes(id) {
      this.selectedpeople = this.selectedpeople.filter(
        person => person.id != id
      );

      if (this.selectedpeople.length == 0) {
        this.msg = "";
      }
    }
  },
  mounted() {
    tmdb.getPopularPeople().then(resp => (this.people = resp.data.results));
    this.msg = "trending";
  }
};
</script>