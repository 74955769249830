<template>
  <div>
    <label
      style="font-weight: 700 !important"
      :class="{'damn-margin': mobile}"
    >Years &middot; {{range}}</label>
    <div style="margin-bottom: 1rem;">
      <div style="margin-top: 1rem" :id="sliderid"></div>
    </div>
  </div>
</template>

<script>
import noUiSlider from "nouislider";
import "nouislider/distribute/nouislider.css";

export default {
  name: "slider",
  props: ["mobile"],
  watch: {
    rangearray: _.debounce(function() {
      if (this.firstrun) {
        this.firstrun = false;
      } else {
        console.log(
          "Emitting yearChange event. New values are: " + this.rangearray
        );
        this.$emit("yearChange", this.rangearray);
      }
    }, 200)
  },
  data() {
    return {
      range: "",
      rangearray: [],
      firstrun: true,
      sliderid: this.randomId()
    };
  },
  methods: {
    randomId() {
      var array = new Uint32Array(1);
      window.crypto.getRandomValues(array);
      var id = "slider" + array.find(Number);
      return id;
    },
    filterPips(value, type) {
      if (value % 10 == 0) {
        return this.mobile ? 2 : 1;
      }
    }
  },
  mounted() {
    var slider = document.getElementById(this.sliderid);

    let currentYear = new Date().getFullYear();
    let thirtyyearsago = currentYear - 30;

    noUiSlider.create(slider, {
      start: [thirtyyearsago, currentYear],
      step: 1,
      range: {
        min: [1900],
        max: [2020]
      },
      format: {
        // 'to' the formatted value. Receives a number.
        to: function(value) {
          return Math.round(value);
        },
        // 'from' the formatted value.
        // Receives a string, should return a number.
        from: function(value) {
          return Number(Math.round(value));
        }
      },
      pips: {
        mode: "steps",
        density: 1,
        filter: this.filterPips,
        format: {
          // 'to' the formatted value. Receives a number.
          to: function(value) {
            return Number.isInteger(value)
              ? value.toFixed(0)
              : value.toFixed(1);
          }
          // 'from' the formatted value.
          // Receives a string, should return a number.
        }
      }
    });

    let vm = this;
    slider.noUiSlider.on("update", function(values) {
      if (!vm.firstrun && vm.rangearray.length > 0) {
        vm.range = values.join(" - ");
        vm.rangearray = values;
      } else {
        vm.range = values.join(" - ");
        vm.rangearray = values;
      }
    });
  }
};
</script>

<style scoped>
.damn-margin {
  margin-left: -0.75rem;
}
</style>