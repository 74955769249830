<template>
  <div>
    <label style="font-weight: 700 !important" :class="{'damn-margin': mobile}">Rating &middot; {{range}}</label>
    <div style="margin-top: 0.75rem;" :id="sliderid"></div>
    <label :id="labelid" v-if="false">{{range}}</label>
  </div>
</template>

<script>
import noUiSlider from "nouislider";
import "nouislider/distribute/nouislider.css";

export default {
  name: "voteslider",
  props: ["mobile"],
  watch: {
    rangearray: _.debounce(function() {
      this.$emit("voteChange", this.rangearray);
    }, 200)
  },
  data() {
    return {
      range: "",
      rangearray: [],
      sliderid: this.randomId(),
      labelid: this.randomId()
    };
  },
  methods: {
    randomId() {
      var array = new Uint32Array(1);
      window.crypto.getRandomValues(array);
      var id = "slider" + array.find(Number);
      return id;
    },
    filterPips(value, type) {
      if (Number.isInteger(value)) return 1;
      else if (String(value).split(".")[1] == "5") return 2;
      //Converts the number to a string; Splits it at the decimal point; Checks the second half of the resulting splitted array; If it ends in 5, then it's displayed. ie. 3.5 is displayed but 6.7 isn't
      else return 0;
    }
  },
  mounted() {
    var pipsSlider = document.getElementById(this.sliderid);

    noUiSlider.create(pipsSlider, {
      start: [0, 10],
      step: 0.1,
      range: {
        min: 0,
        max: 10
      },
      pips: {
        mode: "steps",
        density: 1,
        filter: this.filterPips,
        format: {
          // 'to' the formatted value. Receives a number.
          to: function(value) {
            return Number.isInteger(value)
              ? value.toFixed(0)
              : value.toFixed(1);
          }
          // 'from' the formatted value.
          // Receives a string, should return a number.
        }
      }
    });

    let vm = this;
    pipsSlider.noUiSlider.on("update", function(values) {
      values = values.map(value =>
        Number.isInteger(value)
          ? Number(value).toFixed(0)
          : Number(value).toFixed(1)
      );
      vm.range = values.join(" - ");
      vm.rangearray = values;
    });
  }
};
</script>

<style scoped>
.damn-margin {
  margin-left: -0.75rem;
}
</style>